import { render, staticRenderFns } from "./Voucher.vue?vue&type=template&id=4f9b61c8&scoped=true&"
import script from "./Voucher.vue?vue&type=script&lang=js&"
export * from "./Voucher.vue?vue&type=script&lang=js&"
import style0 from "./Voucher.vue?vue&type=style&index=0&id=4f9b61c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9b61c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f9b61c8')) {
      api.createRecord('4f9b61c8', component.options)
    } else {
      api.reload('4f9b61c8', component.options)
    }
    module.hot.accept("./Voucher.vue?vue&type=template&id=4f9b61c8&scoped=true&", function () {
      api.rerender('4f9b61c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8EX5G3Uz2UjPg/JS/comp/Voucher.vue"
export default component.exports